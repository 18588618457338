import React from 'react';

const Video = ({ videoSrcURL, videoTitle, ...props }) => {
  return (
    <div className="parentVideoWrapper">
      <div className="video">
        <iframe
          width="560"
          height="349"
          src={videoSrcURL}
          title={videoTitle}
          style={{ borderRadius: '1rem' }}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </div>
    </div>
  );
};
export default Video;
