/* eslint-disable react/display-name */
import React from 'react';
import Video from '../components/video';

export default () => {
  return (
    <>
      <div
        style={{
          textAlign: 'center',
          color: '#1f2833',
          fontSize: '3rem',
          paddingTop: '5vh',
        }}
      >
        Showreel
      </div>
      <Video
        videoSrcURL="https://www.youtube.com/embed/DQ8Et1swWyQ"
        videoTitle="Lindsey Chapman showreel"
      />
      <div
        style={{
          textAlign: 'center',
          color: '#1f2833',
          fontSize: '3rem',
          marginTop: '5vh',
        }}
      >
        Voicereel
      </div>
      <Video
        videoSrcURL="https://www.youtube.com/embed/Un2gW1cce8I"
        videoTitle="Lindsey Chapman voicereel"
      />
      <div
        style={{
          textAlign: 'center',
          color: '#1f2833',
          fontSize: '3rem',
          marginTop: '5vh',
        }}
      >
        Netball Commentator: World Cup Final
      </div>
      <Video
        videoSrcURL="https://www.youtube.com/embed/UmX-a8HfVJU"
        videoTitle="Lindsey Chapman netball commentary"
      />
    </>
  );
};
